import Bowser from 'bowser'


type DetectProps = {
  desktop: boolean
  mobile: boolean
  tablet: boolean
  opera: boolean
  safari: boolean
  edge: boolean
  ie: boolean
  chrome: boolean
  firefox: boolean
  uc_browser: boolean
  brave: boolean
  android: boolean
  ios: boolean
  windows: boolean
  linux: boolean
  macos: boolean
  iphone: boolean
  ipad: boolean
  touch: boolean
}


const test = (ua?: string) : DetectProps => {
  if (!ua) {
    ua = window.navigator.userAgent
  }

  const _bowser = Bowser.getParser(ua)


  const platform = _bowser.getPlatformType()
  const browser = _bowser.getBrowserName()
  const os = _bowser.getOSName()
  const model = (_bowser.getPlatform().model || '').toLowerCase()

  const hiddenIpad = os === 'macOS' && navigator.maxTouchPoints > 1
  const brave = !!(navigator as any).brave

  return {
    desktop: platform === 'desktop' && !hiddenIpad,
    mobile: platform === 'mobile',
    tablet: platform === 'tablet' || platform === 'tv' || hiddenIpad,

    opera: browser === 'Opera' || browser === 'Opera Coast',
    safari: browser === 'Safari',
    edge: browser === 'Microsoft Edge',
    ie: browser === 'Internet Explorer',
    chrome: (browser === 'Chrome' || browser === 'Chromium') && !brave,
    firefox: browser === 'Firefox',
    uc_browser: browser === 'UC Browser',
    brave,

    android: os === 'Android',
    ios: os === 'iOS',
    windows: os === 'Windows',
    linux: os === 'Linux',
    macos: os === 'macOS',

    iphone: model === 'iphone',
    ipad: model === 'ipad',

    touch: !!window.matchMedia('(pointer: coarse)').matches
  }
}
let detect : DetectProps = test()

// export { startDetection }
export default detect
