import Component from 'navigation/component/Component'
import { bindMethod } from 'helpers/bind'
import resize from 'helpers/resize'
import anime from 'animejs'
import math from 'helpers/math'
import scroll from 'core/scroll'

type ProductSuperZoomType = {
  refs: {
    zoomClose: HTMLElement
    zoomThumbItems: HTMLElement
    zoomImageContainer: HTMLElement
    images: HTMLImageElement[]
  }
  modules: {}
}

type TouchInfo = {
  x: number
  y: number
  time: number
  start: {
    x: number
    y: number
  }
} | null

class ProductSuperZoom extends Component<ProductSuperZoomType> {
  private target: HTMLImageElement
  private imageHeight: number = null!
  private imageWidth: number = null!
  private raf?: number
  private animating: boolean = false
  private touch: TouchInfo = null

  private style = {
    scale: 0.5,
    top: '0%',
    left: '0%',
    x: 0,
    y: 0,
    targetX: 0,
    targetY: 0
  }

  private needUpdate = false

  constructor (el: HTMLImageElement) {
    const clone = el.cloneNode(true) as HTMLImageElement
    clone.classList.add('zoom-image-clone')
    clone.classList.add('zoomed')
    clone.removeAttribute('data-refs')

    requestAnimationFrame(() => {
      el.parentElement?.appendChild(clone)
    })

    super(clone)

    this.target = el
    this.resize()
  }

  bindEvents (add: boolean): void {
    const method = bindMethod(add)

    // dekstop
    document.body[method]('mousemove', this.onMouseMove)

    // mobile
    document.body[method]('touchstart', this.onTouchStart)
    document.body[method]('touchmove', this.onTouchMove, { passive: false })

    this.el[method]('click', this.unzoom)

    if (add) this.raf = requestAnimationFrame(this.onRequestAnimationFrame)
    else if (this.raf) cancelAnimationFrame(this.raf)
  }

  onRequestAnimationFrame = (): void => {
    this.raf = requestAnimationFrame(this.onRequestAnimationFrame)
    this.render()
  }

  render = (): void => {
    const deltaX = this.style.targetX - this.style.x
    const deltaY = this.style.targetY - this.style.y

    if (Math.abs(deltaX) > 0.1 || Math.abs(deltaY) > 0.1) {
      const inertia = 0.5
      this.style.x += deltaX * inertia
      this.style.y += deltaY * inertia
      this.needUpdate = true
    }

    if (this.needUpdate) {
      const offsetMultiplier = (this.style.scale - .5) * 2

      this.el.style.setProperty('--x', `${this.style.x * offsetMultiplier}%`)
      this.el.style.setProperty('--y', `${this.style.y * offsetMultiplier}%`)
      this.el.style.setProperty('--scale', `${this.style.scale}`)
      this.el.style.top = this.style.top
      this.el.style.left = this.style.left
      this.needUpdate = false
    }
  }

  getTargetPosition = () => {
    const currentImagePosition = this.target.getBoundingClientRect()
    const dimensions = resize.dimensions()

    const offsetTop = this.imageHeight / 4 / dimensions.height
    const offsetLeft = this.imageWidth / 4 / dimensions.width

    const top = ((currentImagePosition.top / dimensions.height) + offsetTop) * 100 + '%'
    const left = ((currentImagePosition.left / dimensions.width) + offsetLeft) * 100 + '%'

    return { top, left }
  }

  zoom = async (event: Event) => {
    this.resize()

    if (event) this.onMouseMove(event as MouseEvent)

    const { top, left } = this.getTargetPosition()

    this.animating = true

    this.style.top = top
    this.style.left = left
    this.style.scale = .5

    this.needUpdate = true
    this.render()

    await anime({
      targets: this.style,
      top: [top, '50%'],
      left: [left, '50%'],
      scale: 1,
      duration: 300,
      easing: 'easeInOutQuad',
      update: (anim) => {
        this.needUpdate = true
      }
    }).finished

    this.animating = false
    this.emit('zoomed')
    scroll.lock()
  }

  unzoom = async () => {
    if (this.animating) return
    const { top, left } = this.getTargetPosition()

    this.animating = true

    await anime({
      targets: this.style,
      top: ['50%', top],
      left: ['50%', left],
      scale: .5,
      duration: 300,
      easing: 'easeInOutQuad',
      update: (anim) => {
        this.needUpdate = true
      }
    }).finished

    this.animating = false
    this.emit('unzoomed')
    scroll.unlock()

    this.el.remove()
  }

  getZoomDelta = () => {
    const yDelta = (this.imageHeight - resize.dimensions().height) / this.imageHeight
    const xDelta = (this.imageWidth - resize.dimensions().width) / this.imageWidth

    return [
      100 * xDelta,
      100 * yDelta
    ]
  }

  getZoomBounds = () => {
    return this.getZoomDelta().map((delta) => [delta * -0.5, delta * 0.5])
  }

  onMouseMove = (event: Event): void => {
    const rect = resize.dimensions()
    const mouseEvent = event as MouseEvent
    const mouseX = mouseEvent.clientX / rect.width
    const mouseY = mouseEvent.clientY / rect.height
    const [boundX, boundY] = this.getZoomDelta()

    const moveX = mouseX * boundX - (boundX / 2)
    const moveY = mouseY * boundY - (boundY / 2)

    this.style.targetY = -moveY
    this.style.targetX = -moveX
  }

  onTouchStart = (event: Event): void => {
    event.preventDefault()
    this.touch = {
      x: (event as TouchEvent).touches[0].clientX,
      y: (event as TouchEvent).touches[0].clientY,
      time: Date.now(),
      start: {
        x: this.style.x,
        y: this.style.y
      }
    }
  }

  onTouchMove = (event: Event): void => {
    event.preventDefault()
    if (!this.touch) return

    const deltaX = (event as TouchEvent).touches[0].clientX - this.touch.x
    const deltaY = (event as TouchEvent).touches[0].clientY - this.touch.y

    let newTargetX = this.touch.start.x + (deltaX / this.imageWidth * 100)
    let newTargetY = this.touch.start.y + (deltaY / this.imageHeight * 100)

    const [[minX, maxX], [minY, maxY]] = this.getZoomBounds()

    newTargetX = math.clamp(newTargetX, minX, maxX)
    newTargetY = math.clamp(newTargetY, minY, maxY)

    this.style.targetX = newTargetX
    this.style.targetY = newTargetY
  }

  onClose = (e: Event) => {
  }

  resize (): void {
    super.resize()
    this.imageHeight = this.el.offsetHeight
    this.imageWidth = this.el.offsetWidth
    this.el.style.marginLeft = `-${this.imageWidth / 2}px`
    this.el.style.marginTop = `-${this.imageHeight / 2}px`
  }

  flush (): void {
    super.flush()
    this.el.remove()
  }
}

export default ProductSuperZoom
