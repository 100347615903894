import modulesMap from 'core/modulesMap'
import PageManager, { RequestOptions } from 'navigation/page-manager/PageManager'

import Component, { BaseComponentType } from '../component/Component'
import './Page.scss'

export interface IPage {
  state: { shown: boolean, hidden: boolean, prepared: boolean }
  shouldRouteInternally?: (el: HTMLElement, pathName: string) => boolean
  internalRouting?: (pathName: string, xhr: XMLHttpRequest, requestOptions: RequestOptions) => Promise<void>

  el: HTMLElement
  pageName: () => string
  askPreload: (previousPage?: string) => Promise<void> | void
  askShow: (previousPage?: string) => Promise<void> | void
  askHide: (nextPage?: string) => Promise<void> | void
  transitionComplete: () => void
  resize: () => void
  flush: () => void
}

export interface IPageClass {
  pageName?: string
  new (el: HTMLElement, options?: any, pageManager?: PageManager): IPage
}

class Page<
  Type extends BaseComponentType = BaseComponentType
> extends Component<Type> implements IPage {
  static pageName: string = 'Page'
  public pageManager?: PageManager
  state = { shown: false, prepared: false, hidden: false }

  pageName () { return (this.constructor as any).pageName || this.constructor.name || true }

  constructor (el: HTMLElement, options?: Type['options'], pageManager?: PageManager) {
    super(el, options)
    this.pageManager = pageManager
    this.bindRefs()
  }

  initialized () {}

  bindModules () {
    super.bindModules()
    this.bindEvents(true)
  }

  getModulesMap () {
    return Object.assign({}, modulesMap)
  }

  /* PREPARE */

  async askPreload (previousPage?: string) {
    this.el.classList.add('preloading')
    this.bindInternalRouter()
    await this.preload(previousPage)
    this.state.prepared = true
  }

  async preload (previousPage?: string) {
    await this.preloadImages()
  }

  /* LOAD IMAGES */

  async preloadImages () {
    const imagesToLoad = this.getImagesToLoad().slice(0, 10)
    if (!imagesToLoad || !imagesToLoad.length) return
    return Promise.all(imagesToLoad.map((v, k) => this.loadImage(v, k, imagesToLoad.length)))
  }

  getImagesToLoad () : HTMLImageElement[] {
    return Array.from(this.el.querySelectorAll('img:not([loading=lazy])[src]')) as HTMLImageElement[]
  }

  loadImage (img: HTMLImageElement, i: number, total: number) {
    if (~img.src.indexOf('svg')) return Promise.resolve()

    return new Promise(resolve => {
      if (img.naturalWidth !== 0 || img.complete) return resolve(img)

      img.onerror = img.onload = () => {
        img.onerror = img.onload = null
        resolve(img)
      }

      img.src = img.src // eslint-disable-line
    })
  }

  /* SHOW */

  async askShow (previousPage?: string) {
    this.el.classList.remove('preloading')
    this.bindModules()
    await this.show(previousPage)
    this.state.shown = true
  }

  async show (previousPage?: string) {
    this.el.style.opacity = '1'
    this.invoke('show')
  }

  transitionComplete () {
    this.invoke('transitionComplete')
  }

  /* HIDE */
  async askHide (nextPage?: string) {
    this.invoke('hide')
    await this.hide(nextPage)
    this.state.hidden = true
  }

  async hide (nextPage?: string) {
    this.el.style.opacity = '0'
  }

  resize () {
    this.invoke('resize')
  }

  flush () {
    super.flush()
  }
}

export default Page
