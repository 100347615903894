import Component from 'navigation/component/Component'
import router from 'core/router'
import { dimensions } from 'helpers/resize'
import math from 'helpers/math'
import './InfiniteLine.scss'

type InfiniteLineType = {
  refs: {}
  modules: {}
}

class InfiniteLine extends Component<InfiniteLineType> {
  public items: HTMLElement[]
  public originalItems: HTMLElement[]
  public originalSize: number = 0
  public clonedItems: HTMLElement[] = []
  public itemSizes: number[] = []

  protected vertical = false
  public totalSize: number = 0
  private screenSize: number = 0
  private _offset = 0

  public total: number

  constructor (el: HTMLElement, {
    vertical = false
  } = {}) {
    super(el)
    this.vertical = vertical
    this.el.classList.add('infinite-line')
    if (vertical) this.el.classList.add('infinite-line--vertical')
    this.originalItems = Array.from(this.el.children) as HTMLElement[]
    this.items = Array.from(this.el.children) as HTMLElement[]
    this.total = this.items.length

    this.resize()
    this.onUpdate()
  }

  set offset (value: number) {
    if (isNaN(value)) return
    this._offset = value % this.totalSize
    this.onUpdate()
  }

  get offset (): number {
    return this._offset
  }

  get itemSize (): number[] {
    return this.itemSizes
  }

  onUpdate = () => {
    let inc = 0
    const current = this.offset

    this.items.forEach((item, index) => {
      const size = this.itemSizes[index]
      const offset = math.wrap(current + inc, -size, this.totalSize - size)
      const isVisible = (offset + size) > 0 && offset < this.screenSize
      if (isVisible) {
        item.style.transform = this.vertical ? `translate3d(0,${offset}px,0)` : `translate3d(${offset}px,0,0)`
        item.style.visibility = 'inherit'
      } else {
        item.style.visibility = 'hidden'
      }

      inc += size
    })
  }

  resize (): void {
    super.resize()

    this.itemSizes = this.items?.map(item => {
      const bbox = item.getBoundingClientRect()
      return this.vertical ? bbox.height : bbox.width
    })

    const multiplier = this.items.length / this.originalItems.length
    this.totalSize = this.itemSizes.reduce((acc, size) => acc + size, 0)
    this.originalSize = this.totalSize / multiplier
    this.el.style.setProperty('--total-size', `${this.totalSize}px`)

    this.screenSize = this.vertical ? dimensions().height : dimensions().width

    this.restructure()

    this.el.style.setProperty('--screen-size', `${this.screenSize}px`)
    this.el.style.setProperty('--min-size', `${this.getMinSize()}px`)
  }

  getMinSize = (): number => {
    const maxItem = this.itemSizes.reduce((acc, item) => Math.max(acc, item), -1)
    return this.totalSize - maxItem
  }

  restructure (): void {
    if (!this.items?.length) return

    let restructure = false

    while (this.getMinSize() < this.screenSize && this.items.length < 50) {
      // const inc = this.items.length
      // const id = inc % this.originalItems.length
      // const clone = this.originalItems[id].cloneNode(true) as HTMLElement
      // this.el.appendChild(clone)
      // this.items.push(clone)
      // this.totalSize += this.vertical ? clone.offsetHeight : clone.offsetWidth

      this.originalItems.forEach((item) => {
        const clone = item.cloneNode(true) as HTMLElement
        clone.classList.add('infinite-line__clone')
        this.el.appendChild(clone)
        this.items.push(clone)
        this.clonedItems.push(clone)
        this.totalSize += this.vertical ? clone.offsetHeight : clone.offsetWidth
      })

      restructure = true
    }

    if (restructure) {
      this.resize()
      router.updatePageLinks()
      this.emit('restructure')
    }
  }

  reset = () => {
    this.resize()
    this.restructure()
    this.onUpdate()
    this.offset = 0
  }

  disable = () => {
    this.el.classList.remove('infinite-line')
    this.reset()

    this.items.forEach((item, index) => {
      item.style.transform = ''
      item.style.visibility = ''
    })
  }

  enable = () => {
    this.el.classList.add('infinite-line')
    this.resize()
    this.restructure()
    this.onUpdate()
  }

  flush (): void {
    this.disable()
    this.clonedItems.forEach((item) => item.remove())
    super.flush()
  }
}

export default InfiniteLine
